import {createApp} from 'vue'
import App from './App.vue'
import {router} from './router';
import {store} from './store';
import apiController from './plugins/ApiController';
import validator from './plugins/Validator';
import VueToast from 'vue-toast-notification';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-toast-notification/dist/theme-sugar.css';

const app = createApp(App);

app.component('Datepicker', Datepicker);

app.use(router)
    .use(store)
    .use(apiController)
    .use(validator)
    .use(VueToast, {
        position: 'top-right'
    })
    .mount('#app');
