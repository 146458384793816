
import {defineComponent} from 'vue';

const Textarea = defineComponent({
  name: 'TextareaComponent',
  data() {
    return {
      value: "",
    }
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    rows: {
      type: Number,
      default: 5,
    },
  },
  methods: {
    updateValue(value: string): void {
      this.$emit('update:modelValue', value)
    }
  }
});

export type TextareaRef = InstanceType<typeof Textarea>;
export default Textarea;
