
import {defineComponent} from 'vue';
import MainText from "@/components/MainText.vue";
import formatDate from "@/helpers/helpers";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: 'FinalInformationView',
  components: {
    MainText,
    Button
  },
  computed: {
    // TODO poprawnie pobierać dane
    openTime(): string {
      return '48';
    },
    date(): string {
      return formatDate(this.$store.getters.userData.create, 'date');
    },
    time(): string {
      return formatDate(this.$store.getters.userData.create, 'time');
    },
    pickUpOnly(): boolean {
      return this.$store.getters.userData.pickUpOnly;
    },
  },
  methods: {
    goToAutoWimar(): void {
      window.open("https://auto-wimar.pl/salon-i-serwis-skody-w-warszawie-kontakt/");
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
});
