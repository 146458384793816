
import {defineComponent} from 'vue';
import MainText from "@/components/MainText.vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  name: 'AppointmentConfirmation',
  components: {
    MainText,
    Button
  },
  methods: {
    goToAutoWimar(): void {
      window.open("https://auto-wimar.pl/salon-i-serwis-skody-w-warszawie-kontakt/");
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
});
