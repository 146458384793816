
import {defineComponent} from 'vue';
import Button from "@/components/Button.vue";
import Input from '@/components/Input.vue';
import {ObjectIdResponse} from '@/types'
import {MutationTypes} from '@/store/mutation-types';
import MainText from "@/components/MainText.vue";

export default defineComponent({
  name: 'SmsNumberView',
  data() {
    return {
      smsNumber: '',
      loadingCheckNumber: false,
      loadingSendSmsAgain: false,
      validSmsNumber: true,
      smsSentAgain: false,
      errorMessage: ''
    }
  },
  components: {
    Button,
    Input,
    MainText,
  },
  computed: {
    phoneNumber(): number {
      return this.$store.getters.phoneNumber;
    },
    isAppointment(): boolean {
      return this.$store.getters.isAppointment;
    },
    orderId(): number {
      return this.$store.getters.orderId;
    },
    emailKey(): string {
      return this.$store.getters.userData.emailKey;
    }
  },
  methods: {
    checkSmsNumber(): void {
      if (this.smsNumber?.length) {
        this.loadingCheckNumber = true;

        this.$apiController.smsCodeVerification(this.orderId, {
          code: this.smsNumber,
        })
            .then((res: ObjectIdResponse): void => {
              let messageCode = '';

              if (res instanceof Response) {
                if (res?.status === 400) {
                  if (res?.body) {
                    this.$apiController.readResponseBody(res.body).then((res: string) => {
                      messageCode = JSON.parse(res)?.messages[0]?.code;
                      this.$toast.error(this.message(messageCode));
                    });
                  } else {
                    this.$toast.error(this.message(messageCode));
                  }
                  this.validSmsNumber = false;
                } else {
                  throw new Error('Bad response status');
                }
              } else {
                this.validSmsNumber = true;

                if (this.isAppointment) {
                  this.$router.push({name: 'appointmentConfirmation'});
                } else {
                  this.$store.commit(MutationTypes.SET_OPEN_CODE, res.id);

                  this.$router.push({name: 'finalInformation'});
                }
              }
            })
            .catch((): void => {
              this.validSmsNumber = false;
              this.$toast.error('Wpisany kod nie jest prawidłowy, spróbuj ponownie');
            })
            .finally(() => {
              this.loadingCheckNumber = false;
            });
      } else {
        this.validSmsNumber = false;
        this.$toast.warning('Wprowadź kod SMS');
      }
    },

    sendSmsNumberAgain(): void {
      this.smsSentAgain = true;
      this.loadingSendSmsAgain = true;

      this.$apiController.sendSmsCodeAgain(this.emailKey, {})
          .then((res: ObjectIdResponse): void => {
            this.smsSentAgain = true;
            this.$toast.success('Kod SMS został ponownie wysłany !');
            this.$store.commit(MutationTypes.SET_ORDER_ID, res.id);
          })
          .catch((): void => {
            this.$toast.error('Wysyłanie kodu SMS nie powiodło się. Spróbuj jeszcze raz lub skontaktuj się z adminstratorem.');
          })
          .finally(() => {
            this.loadingSendSmsAgain = false;
          });
    },

    message(code: string): string {
      switch (code) {
        case 'ORDER_VERIFICATION_CODE_NOT_EQUAL':
          this.errorMessage = 'Wpisany kod nie jest prawidłowy, spróbuj ponownie';

          break;
        case 'ORDER_VERIFICATION_CODE_EXPIRED':
          this.errorMessage = 'Ważność wprowadzonego kodu wygasła';

          break;
        case 'ORDER_VERIFICATION_BOOKING_NOT_POSSIBLE':
          this.errorMessage = 'Czas zlecenia już minął';

          break;
        default:
          this.errorMessage = 'Wpisany kod nie jest prawidłowy, spróbuj ponownie';
      }

      return this.errorMessage;
    },
    scrollToTop() {
      window.scrollTo(0,0);
    }
  },
  mounted() {
    this.scrollToTop();
  }
});
