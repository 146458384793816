import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f9e7490"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["value", "name", "id", "placeholder", "type", "required", "step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-container", { invalid: !_ctx.valid}])
  }, [
    (!_ctx.valid && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "invalid"
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      value: _ctx.modelValue,
      onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
      name: _ctx.id,
      id: _ctx.id,
      ref: "input",
      placeholder: _ctx.placeholder ? `${_ctx.placeholder}${_ctx.required ? '*': ''}` : '',
      onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateValue($event.target.value))),
      type: _ctx.inputType,
      required: _ctx.required,
      step: _ctx.step
    }, null, 40, _hoisted_2)
  ], 2))
}