export const state = {
  userData: {
    firstName: '',
    create: '',
    emailKey: '',
    phone: {
      last3: '',
      dialCode: null
    },
    pickUpOnly: false
  },
  phoneNumber: '',
  orderId: null,
  openCode: null,
  isAppointment: false
};

export type State = typeof state;
