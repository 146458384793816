export default function formatData(time: string, type: string): string {
    let options = {};

    if (time) {
        const parsedTime = time.replace(/-/g, "/");

        switch (type) {
            case 'date':
                options = {year: 'numeric', month: 'numeric', day: 'numeric'};

                return new Date(parsedTime).toLocaleDateString("pl", options);
            case 'time':
                options = {hour: 'numeric', minute: 'numeric'};

                return new Date(parsedTime).toLocaleTimeString("pl", options);
            default:
                options = {year: 'numeric', month: 'numeric', day: 'numeric'};

                return new Date(parsedTime).toLocaleDateString("pl", options);
        }
    }

    return '';
}