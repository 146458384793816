
import {defineComponent} from 'vue';
import Button from '@/components/Button.vue';

const Form = defineComponent( {
  name: "Form",
  components: {
    Button
  },
  props: {
    title: {
      type: String,
      default: 'Formularz'
    },
    buttonText: {
      type: String,
      default: 'Formularz'
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    submitForm(): void {
      this.$emit('submit');
    }
  }
});

export type FormRef = InstanceType<typeof Form>;
export default Form;
