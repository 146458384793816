
import {defineComponent} from 'vue';

const Checkbox = defineComponent({
  name: 'CheckboxComponent',
  props: {
    checkboxId: {
      type: String,
      default: '',
    },
    valid: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    updateValue(value: string): void {
      this.$emit('update:modelValue', value)
    }
  }
});
export default Checkbox;
