import {App} from "vue";

class Validator {
    letterRegex: RegExp;
    notEmptyRegex: RegExp;
    phoneNumberRegex: RegExp;
    emailRegex: RegExp;

    constructor() {
        this.letterRegex = /^[-AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻżQqXx. ']+$/u;
        this.notEmptyRegex = /^(?!\s*$).+/;

        // eslint-disable-next-line
        this.phoneNumberRegex = /^(?:\(?\+?48)?(?:[-\.\(\)\s]*(\d)){9}\)?$/;

        // eslint-disable-next-line
        this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    letterValidator(value: string) {
        return this.letterRegex.test(value) || value.length === 0;
    }

    notEmptyValidator(value: string) {
        return this.notEmptyRegex.test(value);
    }

    phoneNumberValidator(value: string) {
        return this.phoneNumberRegex.test(value);
    }

    emailValidator(value: string) {
        return this.emailRegex.test(value);
    }

    min4Validator(value: string) {
        return value.length > 3;
    }
}

export default {
    install: (app: App): void => {
        app.config.globalProperties.$validator = new Validator();
    }
}