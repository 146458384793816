import { ActionTree, ActionContext } from 'vuex';
import { State } from './state';
import { Mutations } from './mutations';
// import { ActionTypes } from './action-types';
// import { MutationTypes } from './mutation-types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// type AugmentedActionContext = {
//   commit<K extends keyof Mutations>(
//     key: K,
//     payload: Parameters<Mutations[K]>[1]
//   ): ReturnType<Mutations[K]>
// } & Omit<ActionContext<State, State>, 'commit'>;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Actions {
  // [ActionTypes.GET_COUTNER](
  //   { commit }: AugmentedActionContext,
  //   payload: number
  // ): Promise<number>
}

export const actions: ActionTree<State, State> & Actions = {
  // [ActionTypes.GET_COUTNER]({ commit }) {
  //   return new Promise((resolve) => {
  //     setTimeout(() => {
  //       const data = 256;
  //       commit(MutationTypes.SET_COUNTER, data);
  //       resolve(data);
  //     }, 500);
  //   });
  // },
};
