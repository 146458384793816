import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["date-picker-container", { invalid: !_ctx.valid}])
  }, [
    (!_ctx.valid && _ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "invalid"
        }, _toDisplayString(_ctx.errorMessage), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createVNode(_component_Datepicker, {
      modelValue: _ctx.datetime,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.datetime) = $event)),
      onOpen: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateValue(_ctx.datetime))),
      onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateValue(_ctx.datetime))),
      onCleared: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateValue(_ctx.datetime))),
      id: _ctx.id,
      ref: "datepicker",
      class: "datapicker-container",
      value: _ctx.modelValue,
      format: "dd.MM.yyyy",
      previewFormat: "dd.MM.yyyy",
      minDate: _ctx.minDate,
      maxDate: _ctx.maxDate,
      enableTimePicker: false,
      placeholder: _ctx.placeholder,
      inputClassName: "datepicker-input",
      autoApply: true,
      closeOnAutoApply: true,
      escClose: true,
      closeOnScroll: true,
      openMenu: true,
      textInput: true,
      required: true,
      locale: "pl",
      hideOffsetDates: true,
      textInputOptions: _ctx.textInputOptions,
      dayNames: ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb', 'Nd'],
      onKeydown: _withKeys(_ctx.closeDataPicker, ["tab"])
    }, null, 8, ["modelValue", "id", "value", "minDate", "maxDate", "placeholder", "textInputOptions", "onKeydown"])
  ], 2))
}