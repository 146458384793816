import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-424688ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-text-container" }
const _hoisted_2 = { class: "text-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title", {}, undefined, true),
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ])
  ]))
}