import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9982d3fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-container" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.checkboxId,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateValue($event.target.checked)))
    }, null, 40, _hoisted_2),
    _createElementVNode("span", {
      class: _normalizeClass(["checkmark", { invalid: !_ctx.valid}])
    }, null, 2),
    _renderSlot(_ctx.$slots, "checkbox-label", {}, undefined, true)
  ]))
}