
import {defineComponent} from 'vue';

const DottedNumber = defineComponent({
  name: 'DottedNumberComponent',
  data() {
    return {
      numberLength: 6,
    }
  },
  props: {
    last3Number: {
      type: String,
      default: ''
    },
  },
});
export default DottedNumber;
