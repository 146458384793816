
import {defineComponent} from 'vue';
import Input from '@/components/Input.vue';
import Form from '@/components/Form.vue';
import Checkbox from '@/components/Checkbox.vue';
import Textarea from '@/components/Textarea.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import {AppointmentResponse, ErrorMessage} from '@/types'
import {MutationTypes} from "@/store/mutation-types";
import DatepickerInput from "@/components/DatepickerInput.vue";

export default defineComponent({
  name: 'MakeAppointmentView',
  data() {
    return {
      id: 0,
      name: "",
      phoneNumber: "",
      emailAddress: "",
      datetime: '',
      description: "",
      wantGetReplacementCar: false,
      regulationsChecked: false,
      validRegulationsChecked: true,
      loading: false,
      validated: false,
      minDate: new Date(),
      errorMessages: new Map<string, string>()
    }
  },
  components: {
    DatepickerInput,
    Input,
    Form,
    Checkbox,
    Textarea,
    PhoneNumberInput
  },
  methods: {
    toggleOpenCalendar() {
      (this.$refs['datepicker'] as any).openDataPicker();
    },
    validOnInput() {
      if (this.validated) {
        this.validAppointment();
      }
    },
    validAppointment(): boolean {
      let valid = true;
      this.errorMessages = new Map<string, string>();

      if (!this.$validator.notEmptyValidator(this.name)) {
        valid = false;

        this.errorMessages.set('full_name', 'Ta wartość nie powinna być pusta.');
      }

      if (!this.phoneNumber?.length) {
        valid = false;

        this.errorMessages.set('phone', 'Ta wartość nie powinna być pusta.');
      }

      if (!this.emailAddress?.length) {
        valid = false;

        this.errorMessages.set('email', 'Ta wartość nie powinna być pusta.');
      } else if (!this.$validator.emailValidator(this.emailAddress)) {
        valid = false;

        this.errorMessages.set('email', 'Ta wartość nie jest prawidłowym adresem email.');
      }

      if (!this.datetime) {
        valid = false;

        this.errorMessages.set('date', 'Ta wartość nie powinna być pusta.');
      }

      if (!this.regulationsChecked) {
        valid = false;
      }

      return valid;
    },
    getErrorMessage(key: string): string | undefined {
      return this.errorMessages.get(key) ? this.errorMessages.get(key) : '';
    },
    makeAppointment() {
      this.validRegulationsChecked = this.regulationsChecked;
      this.validated = true;

      const formValid = this.validAppointment();

      if (formValid) {
        this.loading = true;
        this.errorMessages = new Map<string, string>();

        this.$apiController.makeAppointment({
          full_name: this.name,
          phone: this.phoneNumber,
          date: this.dateToTimestamp(this.datetime),
          email: this.emailAddress,
          description: this.description,
          replacement_car: this.wantGetReplacementCar,
        })
            .then((res: AppointmentResponse) => {
              if (res instanceof Response) {
                if (res?.status === 400) {
                  if (res?.body) {
                    this.$apiController.readResponseBody(res.body).then((responseBody: string) => {
                      this.$toast.warning('Uzupełnij poprawnie formularz.');

                      JSON.parse(responseBody).messages.forEach((message: ErrorMessage) => {
                        this.errorMessages.set(message.code, message.message);
                      });
                    });
                  }
                }
              } else {
                this.$store.commit(MutationTypes.SET_ORDER_ID, res.id);
                this.$store.commit(MutationTypes.SET_PHONE_NUMBER, res.phone);
                this.$store.commit(MutationTypes.SET_USER_EMAIL_KEY, res.emailKey);
                this.$store.commit(MutationTypes.SET_IS_APPOINTMENT, true);

                this.$router.push({name: 'smsNumber'});
              }
            })
            .catch((err: Error) => {
              console.error('err', err);

              this.$toast.error('Wysłanie formularza nie powiodło się, spróbuj jeszcze raz.');
            })
            .finally(() => {
              this.loading = false;
            });
      } else {
        this.$toast.warning('Uzupełnij poprawnie formularz.');
      }
    },
    dateToTimestamp(strDate: string): number {
      let date = Date.parse(strDate);

      return date / 1000;
    },
    setMaxDateFromToday(days: number) {
      const now = new Date();
      const maxDate = now.setDate(now.getDate() + days);

      return new Date(maxDate);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.scrollToTop()
  },
  created() {
    this.minDate = this.setMaxDateFromToday(7);

    this.$store.commit(MutationTypes.SET_IS_APPOINTMENT, true);
  }
});
