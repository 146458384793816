import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-container" }
const _hoisted_2 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
    _renderSlot(_ctx.$slots, "content"),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        title: _ctx.buttonText,
        loading: _ctx.loading,
        onClick: _ctx.submitForm
      }, null, 8, ["title", "loading", "onClick"])
    ])
  ]))
}