
import {defineComponent} from 'vue';

const DatepickerInput = defineComponent({
  name: 'DatepickerInputComponent',
  data() {
    return {
      datetime: null,
      textInputOptions: {
        openMenu: true,
        tabSubmit: true,
        format: 'dd.MM.yyyy'
      },
      value: "",
    }
  },
  props: {
    modelValue: {
      type: [String, Date],
      default: ''
    },
    id: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    maxDate: {
      type: Date,
    },
    minDate: {
      type: Date,
    },
  },
  computed: {
    datepicker() {
      const dateTimeInput: HTMLElement | null = document.querySelector('.datapicker-container input');

      return dateTimeInput ? dateTimeInput : null;
    }
  },
  methods: {
    updateValue(value: string | Date): void {
      this.$emit('update:modelValue', value);
    },
    openDataPicker() {
      (this.$refs['datepicker'] as any).openMenu();
    },
    closeDataPicker(event: Event) {
      const target = event?.target as HTMLElement;

      (this.$refs['datepicker'] as any).closeMenu();

      target.blur();
    },
  }
});

export default DatepickerInput;
