
import {defineComponent} from 'vue';
import {VueTelInput} from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

const PhoneNumberInput = defineComponent({
  name: 'PhoneNumberInputComponent',
  components: {
    VueTelInput
  },
  data() {
    return {
      phoneNumber: "",
      phoneIsValidForSelectedCountry: false,
      focused: false,
    }
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    phoneIsValid: {
      type: Boolean,
      default: true,
    },
    validated: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    phoneNumberDialCode(): number {
      return this.$store.getters.userData.phone.dialCode ? this.$store.getters.userData.phone.dialCode : 48;
    },
    errorMessage() {
      return (!this.phoneIsValidForSelectedCountry && this.phoneIsValid) ? 'Podany numer telefonu jest niepoprawny dla tego kraju' : 'Wprowadzony numer telefonu jest niepoprawny';
    },
    inputOptions() {
      return {
        maxlength: 20,
        placeholder: 'Wprowadź numer telefonu',
        showDialCode: true,
        name: this.id,
        id: this.id
      };
    },
    dropdownOptions() {
      return {
        disabled: true,
        readonly: true,
        showFlags: true,
        showDialCode: true,
        autocomplete: 'off',
      }
    },
    allCountries() {
      return [
        {
          "dialCode": this.phoneNumberDialCode,
        }
      ];
    },
    defaultCountry() {
      if (this.allCountries && this.allCountries.length) {
        return this.allCountries[0];
      } else {
        return {
          "dialCode": 48,
        }
      }
    }
  },
  methods: {
    onValidate(value: any): void {
      this.phoneIsValidForSelectedCountry = value.valid;
    },
    updateValue(value: string): void {
      this.$emit('update:modelValue', value)
    },
    onFocus(): void {
      this.focused = true;

      this.validateNumber();
    },
    onBlur(): void {
      this.focused = false;
      this.validateNumber();
    },
    onInput(event: Event): void {
      const target = event.target as HTMLInputElement;

      this.validateNumber();

      if (target.value) {
        this.updateValue(target.value);
      }
    },
    validateNumber(): void {
      const prefixNumber = '+' + this.defaultCountry.dialCode + ' ';

      if (this.phoneNumber && this.phoneNumber.length <= prefixNumber.length) {
        this.phoneNumber = prefixNumber
      } else if (!this.phoneNumber.startsWith(prefixNumber)) {
        this.phoneNumber = prefixNumber + this.phoneNumber.slice(prefixNumber.length);
      }

      let formatted = ""

      if (this.phoneNumber && this.phoneNumber.length > prefixNumber.length) {
        for (const letter of this.phoneNumber) {
          if (letter.match(/[0-9\s]/)) {
            formatted += letter;
          }
        }

        this.phoneNumber = '+' + formatted.replace(/  +/g, ' ');
      }
    }
  }
});

export type PhoneNumberInputRef = InstanceType<typeof PhoneNumberInput>;
export default PhoneNumberInput;
