
import {defineComponent} from 'vue';

const Input = defineComponent({
  name: 'InputComponent',
  data() {
    return {
      value: "",
    }
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    inputType: {
      type: String,
      default: 'name',
    },
    required: {
      type: Boolean,
      default: false,
    },
    step: {
      type: Number
    }
  },
  methods: {
    updateValue(value: string | Date): void {
      this.$emit('update:modelValue', value);
    }
  }
});

export type InputRef = InstanceType<typeof Input>;
export default Input;
