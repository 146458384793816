
import {defineComponent} from "vue";
import Button from "@/components/Button.vue";

export default defineComponent({
  components: {
    Button,
  },
  methods: {
    goToAutoWimar(): void {
      window.open("https://auto-wimar.pl/salon-i-serwis-skody-w-warszawie-kontakt/");
    },
    makeAnAppointment(): void {
      this.$router.push({name: 'makeAppointment'});
    },
    //
    // goToEmail(): void {
    //   this.$router.push({path: "/email/6lCRYmOw29bgxQMvI8yW3kuWhZV4mdnt"});
    // },
  },
});
