
import {defineComponent} from 'vue';
import Button from "@/components/Button.vue";
import Checkbox from '@/components/Checkbox.vue';
import DottedNumber from '@/components/DottedNumber.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import {ObjectIdResponse} from '@/types'
import {MutationTypes} from "@/store/mutation-types";

export default defineComponent({
  name: 'PhoneNumberView',
  data() {
    return {
      loading: false,
      validated: false,
      phoneNumber: '',
      validPhoneNumber: true,
      regulationsChecked: false,
      personalDataProcessingChecked: false,
      validRegulationsChecked: true,
      validPersonalDataProcessingChecked: true,
    }
  },
  components: {
    Button,
    PhoneNumberInput,
    Checkbox,
    DottedNumber,
  },
  computed: {
    phoneNumberLast3(): string {
      return this.$store.getters.userData.phone.last3;
    }
  },
  methods: {
    checkPhoneNumber(): void {
      this.validated = true;
      this.validRegulationsChecked = this.regulationsChecked;
      this.validPersonalDataProcessingChecked = this.personalDataProcessingChecked;

      this.phoneNumber = this.phoneNumber.replace(/\s/g, '');

      if (this.phoneNumber.slice(-3) === this.phoneNumberLast3) {
        this.validPhoneNumber = true;

        if (this.regulationsChecked && this.personalDataProcessingChecked) {
          this.loading = true;

          this.$apiController.phoneNumberVerification(this.$store.getters.userData.emailKey, {
            phone: this.phoneNumber,
          })
              .then((res: ObjectIdResponse): void => {
                if (res instanceof Response) {
                  if (res?.status === 400) {
                    this.validPhoneNumber = false;
                    this.$toast.error('Niestety, w naszej bazie nie ma takiego numeru telefonu, spróbuj jeszcze raz.');
                  } else {
                    throw new Error('Bad response status');
                  }
                } else {
                  this.$store.commit(MutationTypes.SET_PHONE_NUMBER, this.phoneNumber);
                  this.$store.commit(MutationTypes.SET_ORDER_ID, res.id);
                  this.$store.commit(MutationTypes.SET_IS_APPOINTMENT, false);

                  this.$router.push({name: 'smsNumber'});
                }
              })
              .catch((): void => {
                this.validPhoneNumber = false;
                this.$toast.error('Coś poszło nie tak... Spróbuj jeszcze raz lub skontaktuj się z adminstratorem.');
              })
              .finally(() => {
                this.loading = false;
              });
        } else {
          this.$toast.error('Zaznacz wszystkie wymagane zgody');
        }
      } else {
        this.validPhoneNumber = false;
        this.$toast.error('Wprowadzony numer telefonu jest niepoprawny');
      }
    },
  }
});
