export const ROUTING_URLS = {
  ANYTHING: '/:pathMatch(.*)*',
  HOME: '',
  EMAIL: '/email/:email',
  PAYMENT_CONFIRMATION: '/payment-confirmation',
  PAYMENT_FAILED: '/payment-failed',
  REGULATIONS: '/regulations',
  MAKE_AN_APPOINTMENT_EN: '/make-appointment',
  MAKE_AN_APPOINTMENT_PL: '/umow-wizyte',
};
