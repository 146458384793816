import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33c6717d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dotted-number-container" }
const _hoisted_2 = { class: "dots" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberLength, (index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "dot"
        }))
      }), 128))
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, _toDisplayString(_ctx.last3Number), 1)
    ])
  ]))
}