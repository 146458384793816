
  import {defineComponent} from 'vue';
  import Header from '@/components/Layout/Header.vue';
  import Footer from '@/components/Layout/Footer.vue';

  export default defineComponent({
    components: {
      Header,
      Footer,
    },
  });

